// safari 13.1 미만일 때 동작 모킹
export const animatePolyfill = () => {
  if (!Object.prototype.hasOwnProperty.call(Element.prototype, 'animate')) {
    const animate = () => {
      const animation: { onfinish: Function | null } = {
        onfinish: null,
      };
      requestAnimationFrame(() => animation.onfinish?.());
      return animation;
    };

    Object.assign(Element.prototype, { animate });
  }
};
