import 'core-js/stable';
import 'regenerator-runtime/runtime';

import VueCompositionAPI from '@vue/composition-api';
import Vue from 'vue';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import Vuex from 'vuex';
import { animatePolyfill } from '@/exportables/polyfills';
import HeraPlugin from '@/plugins/HeraPlugin';
import { router } from '@/router/career';
import { useStore } from '@/store/career';

if (process.env.NODE_ENV !== 'production' && process.env.MSW_CAREER === 'true') {
  const { initMSW } = require('@/exportables/apis/career/__mocks__/init-msw.ts');
  initMSW();
}

Vue.use(HeraPlugin);
Vue.use(VueCompositionAPI);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(Vuelidate);
Vue.use(VueLazyload, {
  attempt: 1,
  preLoad: 1,
  observer: true,
  observerOptions: {
    rootMargin: '0px',
    threshold: 1,
  },
});

animatePolyfill();

const store = useStore();

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#app-career',
    name: 'AppCareer',
    router,
    store,
    render(h) {
      return h('RouterView');
    },
  });
});
