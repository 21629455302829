import qs from 'qs';
import { codemirror } from 'vue-codemirror';
import VueMarkdown from 'vue-markdown';
import Autocomplete from '../components/shared/Autocomplete';
import SvgIcon from '../components/shared/SvgIcon';
import camelCase from './camelCase';
import { distanceOfTimeInWords } from '@/helpers/datetime';
import { useI18n } from '@/helpers/i18n';

const I18n = useI18n();

const HeraPlugin = {
  install(Vue) {
    // instance methods
    Object.assign(Vue.prototype, {
      $getDataFrom: async function (url, params = {}) {
        const response = await this.$http.get(url, {
          params,
          paramsSerializer: (params) => (qs.stringify(params, { arrayFormat: 'brackets' })),
        });
        return response.data;
      },
    });

    // global components
    Vue.component('SvgIcon', SvgIcon);
    Vue.component('Autocomplete', Autocomplete); // eslint-disable-line vue/multi-word-component-names
    Vue.component('VueMarkdown', VueMarkdown);
    Vue.component('Codemirror', codemirror); // eslint-disable-line vue/multi-word-component-names

    // global filters

    Vue.filter('thousandsSeparator', function (value) {
      return value.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    });

    Vue.filter('truncate', function (value, length = 30) {
      if (!value) return '';

      if (value.length <= length) return value;
      return value.slice(0, length);
    });

    Vue.filter('stringifyValue', function (data) {
      if (!data) return '';

      if (Array.isArray(data)) {
        return data.map((d) => stringifyValue(d.type, d.value)).join(', ');
      } else {
        return stringifyValue(data.type, data.value);
      }
    });

    Vue.filter('capitalize', function (value) {
      if (!value) return '';
      const newValue = value.toString();
      return newValue.charAt(0).toUpperCase() + newValue.slice(1);
    });

    Vue.filter('camelize', function (value, uppercaseFirstLetter = true) {
      if (!value) return '';
      return camelCase(value.toString(), { pascalCase: uppercaseFirstLetter });
    });

    Vue.filter('roundedNumber', function (value, precision) {
      return parseFloat(value || 0).toFixed(precision);
    });

    Vue.filter('localeName', function (value) {
      return I18n.t(`locale.${value}`);
    });

    Vue.filter('microToMilli', function (value) {
      return parseFloat(value) / 1000;
    });

    Vue.filter('byteToMegabyte', function (value) {
      return parseFloat(value) / (1000 * 1000);
    });

    Vue.filter('formatDate', function (date, format) {
      return moment(new Date(date)).format(format);
    });

    Vue.filter('distanceOfTimeInWords', function (from, to = new Date(), options = {}) {
      return distanceOfTimeInWords(from, to, options);
    });

    Vue.filter('year_month', function (value) {
      return I18n.l('date.formats.year_month', value);
    });

    Vue.filter('i18n_time', function (value, format) {
      return I18n.l(`time.formats.${format}`, value);
    });

    Vue.filter('i18n', function (value, interpolateValue = {}) {
      return I18n.t(`${value}`, interpolateValue);
    });

    Vue.filter('translateByDomain', function (value, site = undefined, interpolateValue = {}) {
      return I18n.t(`${site}.${value}`, { ...interpolateValue, defaults: [{ scope: value }] });
    });

    Vue.filter('remainTime', function (timeDiff) {
      let sec = Math.trunc(timeDiff / 1000);
      const day = Math.trunc(sec / 60 / 60 / 24);
      sec = (sec - (day * 60 * 60 * 24));
      const hour = Math.trunc(sec / 60 / 60);
      sec = (sec - (hour * 60 * 60));
      const min = String(Math.trunc(sec / 60)).padStart(2, '0');
      sec = String(sec - (min * 60)).padStart(2, '0');

      let remainTime = '';
      if (day > 0) {
        remainTime = `${day}${I18n.t('datetime.prompts.day')}`;
      } else if (hour > 0) {
        remainTime = `${hour}${I18n.t('datetime.prompts.hour')}`;
      } else if (min > 0) {
        remainTime = `${min}${I18n.t('datetime.prompts.minute')}`;
        if (timeDiff <= 300000) {
          remainTime = `${min}${I18n.t('datetime.prompts.minute')} ${sec}${I18n.t('datetime.prompts.second')}`;
        }
      } else {
        remainTime = `${sec}${I18n.t('datetime.prompts.second')}`;
      }
      return `${remainTime}`;
    });

    Vue.filter('simplifiedTimeDiff', function (from, to = new Date) {
      const timeDiff = Math.trunc((to - from) / 1000);

      if (timeDiff < 60) {
        return `${timeDiff}${I18n.t('datetime.prompts.second')}`;
      } else if (timeDiff >= 60 && timeDiff < 3600) {
        return `${Math.trunc(timeDiff / 60)}${I18n.t('datetime.prompts.minute')}`;
      } else {
        return `${Math.trunc(timeDiff / 3600)}${I18n.t('datetime.prompts.hour')}`;
      }
    });

    Vue.filter('zeroPad', function (value, targetLength = 2) {
      return String(value).padStart(targetLength, '0');
    });

    Vue.mixin({
      data() {
        return { I18n: useI18n() };
      },
      computed: {
        imgLocale() {
          return I18n.locale === 'ko' ? 'ko' : 'en';
        },
      },
      methods: {
        isPresent(variable) {
          if (Array.isArray(variable)) {
            const array = variable.filter((item) => this.isPresent(item));
            return array.length > 0;
          } else if (typeof (variable) === 'object' && !!variable && variable.constructor === Object) {
            let present = false;
            for (const value of Object.values(variable)) {
              present = present || this.isPresent(value);
            }
            return present;
          } else {
            return !!variable;
          }
        },
        async getDataFrom(url) {
          const response = await fetch(url);
          return await response.json();
        },
        // eslint-disable-next-line camelcase
        html_output(text) {
          if (text === undefined) return;
          return text.replace(/(?:\r\n|\r|\n)/g, '<br/>');
        },
        dig(keys, objs) {
          const get = (keys, objs) =>
            keys.reduce((obj, key) =>
              (obj && obj[key]) ? obj[key] : null, objs);
          return get(keys, objs);
        },
        clamp(value, min, max) {
          return Math.min(Math.max(value, min), max);
        },
        getValidationMessage(messages, validations = []) {
          let keys;
          if (validations.length === 0) {
            keys = Object.keys(messages);
          } else {
            keys = extractValidatorKeys(validations);
          }
          // check to make sure all validators have error messages
          const missing = keys.filter((x) => !(x in messages));
          if (missing.length) {
            console.warn(
              'Validators missing validation messages: %s',
              missing.join(', '),
            );
            // remove keys that don't have validation messages
            keys = keys.filter((x) => missing.indexOf(x) < 0);
          }
          const keyLen = keys.length;

          // Vue component method
          // Given a vuelidate field object, maybe return an error messsage
          return function (field) {
            if (!field.$dirty) return null;
            let key;
            for (let i = 0; i < keyLen; i++) {
              key = keys[i];
              if (field[key] === false) {
                return messages[key](field.$params[key]);
              }
            }
            return null;
          };
        },
        camelToKebab(s) {
          return s.replace(/([a-z0-9])([A-Z])/g, '$1-$2')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
            .toLowerCase();
        },
        snakeToKebab(s) {
          return s.replace(/_/g, '-');
        },
        camelToSnake(s) {
          return s.replace(/([a-z0-9])([A-Z])/g, '$1_$2')
            .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1_$2')
            .toLowerCase();
        },
        scrollToTop() {
          window.scrollTo(0, 0);
        },
        isEmptyObject(obj) {
          return obj.constructor === Object && Object.keys(obj).length === 0;
        },
        t(...args) {
          return I18n.t(...args);
        },
        tbd(...args) {
          return I18n.tbd(...args);
        },
        formatDate(date, format) {
          return moment(new Date(date)).format(format);
        },
        thousandsSeparator(value, seperator = ',') {
          return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, seperator);
        },
      },
    });
  },
};

const isFn = (x) => typeof x === 'function';

// Given a Vuelidate validations object, find the validator keys
function extractValidatorKeys(validations, validators = []) {
  const keys = Object.keys(validations);
  validators.push(...keys.filter((x) => isFn(validations[x])));
  keys.filter((x) => !isFn(validations[x]))
    .forEach((x) => extractValidatorKeys(validations[x], validators));
  return validators;
}

function jsStringEscape(string) {
  return ('' + string).replace(/["'\\\n\r\u2028\u2029]/g, function (character) {
    // Escape all characters not included in SingleStringCharacters and
    // DoubleStringCharacters on
    // http://www.ecma-international.org/ecma-262/5.1/#sec-7.8.4
    switch (character) {
      case '"':
      case '\'':
      case '\\':
        return '\\' + character;
      // Four possible LineTerminator characters need to be escaped:
      case '\n':
        return '\\n';
      case '\r':
        return '\\r';
      case '\u2028':
        return '\\u2028';
      case '\u2029':
        return '\\u2029';
    }
  });
}

function wrapDoubleQuote(string) {
  return `'${jsStringEscape(string)}'`;
}

function quoteValue(type, value) {
  if (!type.includes('string')) return value;

  if (type.includes('[][]')) {
    return value.map((v) => v.map(wrapDoubleQuote));
  } else if (type.includes('[]')) {
    return value.map(wrapDoubleQuote);
  } else {
    return wrapDoubleQuote(value);
  }
}

function stringifyValue(type, value) {
  const quoted = quoteValue(type, value);

  if (type.includes('[][]')) {
    return `[${quoted.map((v) => `[${v.toString()}]`).join(',')}]`;
  } else if (type.includes('[]')) {
    return `[${quoted.toString()}]`;
  } else {
    return quoted.toString();
  }
}

export default HeraPlugin;
